<template>
	<div class="article-box">
		<div class="box">
				<el-input style="width: 25%;height: 54px;" placeholder="请输入搜索关键字" v-model="keyword">
					<el-button style="height: 54px;" slot="append" icon="el-icon-search" @click="machineSerchList(1)">
					</el-button>
				</el-input>
			</div>
		<el-col class="col" :span="8">
			
			<p class="pc">
				<span @click="$router.go(-1)" style="color: #409EFF;cursor: pointer;">设备列表</span> /
				<span>设备批次管理</span>
				
				</p>
			
			<el-button class="btn" type="text" @click="dialogFormVisible = true">创建批次</el-button>


			<!-- 弹窗 -->
			<el-dialog title="创建批次" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
				<el-form :model="machinesAddBatch">
					<el-form-item label="批次名:">
						<el-input v-model="machinesAddBatch.agent" style="width: 260px; float: left"
							placeholder="请输入批次名" clearable>
						</el-input>
					</el-form-item>

					<el-form-item style="margin-top:5px">
						<el-button @click="dialogFormVisible = false">取消</el-button>
						<el-button type="primary" @click="Adds">添加</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

		</el-col>
		<el-table :data="machineData" style="width: 100%" v-loading="loading" >
			<!-- <el-table-column prop="serial" label="序号" type="index" align="center"></el-table-column> -->
			<el-table-column prop="id" label="序号" align="center"width="200"></el-table-column>
			<el-table-column prop="batch" label="批次名称" align="center"width="350"></el-table-column>
			<el-table-column prop="machine_num" label="设备数量" align="center"width="250"></el-table-column>
			<el-table-column prop="create_time" label="创建时间 " align="center" width="350"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="350">
				<template slot-scope="scope">
					<el-button class="el_buu" @click="temperature(scope.row)" type="text" size="small">校温管理</el-button>
					<el-button class="el_buu" @click="temperatures(scope.row)" type="text" size="small">批次管理</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		machines,
		machinesAddBatch
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
                keyword: '', // 搜索关键字
				machineData: [], // 设备列表
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数

				dialogFormVisible: false,
				machinesAddBatch: {}, // 添加


			}
		},
		mounted() {
			this.machineSerchList(1) // 获取第一页设备列表
			
		},
		methods: {
			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
                    batch: this.keyword,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machines(data).then(res => {
					this.machineData = res.data.list // 设备列表
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},

			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
				this.$refs.mydiv.scrollTop = 0;
			},
			

			Adds() { // 点击添加
				if (!this.machinesAddBatch.agent) {
					this.$message.error('请补全信息')
				} else {
					const data = {
						batch: this.machinesAddBatch.agent,

					}
					machinesAddBatch(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '添加成功',
								type: 'success'
							})
							this.dialogFormVisible = false //关闭添加弹窗
							this.machineSerchList(this.currpage) // 刷新
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			temperature(item) {
				this.$router.push({
					name: "management",
					params: {
						batch_id: item.id

					}
				})
			},
			temperatures(item) {
				this.$router.push({
					name: "machineBatch",
					params: {
						batch_id: item.id,
						machine_id: item.batch
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.col {

		width: 100%;
		display: flex;
		justify-content: space-between;

		.pc {
			font-size: 1.875rem;
		}

		.inp {
			width: 33.333%;
		}

		.btn {
			width: 6.125rem;
			height: 2.875rem;
			background-color: #003882;
			font-size: 1.25rem;
			color: #ffffff;
			font-size: 1.25rem;
		}

		.el-dialog {
			width: 23rem;
			height: 15.875rem;

			.el-form {

				display: flex;
				justify-content: space-around;
			}

			.el-form-item {
				font-size: 1rem;
				display: flex;

				.input {
					width: 8.75rem;
					height: 1.125rem;
				}
			}

		}

	}


	.el_buu {
		width: 4.125rem;
		height: 1.875rem;
		color: #008080;
		font-size: 0.8125rem;
	}
</style>
